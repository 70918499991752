<template>
  <div>
    <ul v-if="pages > 1">
      <li v-if="page > 1"><a href="#" class="flix-btn flix-btn-default" @click.prevent="setPage(1)">1</a></li>
      <li v-if="page > 2"><a href="#" class="flix-btn flix-btn-default" @click.prevent="setPage(page-1)">{{ page - 1 }}</a></li>
      <li><b class="flix-btn flix-btn-success">{{ page }}</b></li>
      <li v-if="page + 1 < pages"><a href="#" class="flix-btn flix-btn-default" @click.prevent="setPage(page+1)">{{ page + 1 }}</a></li>
      <li v-if="pages != page"><a href="#" class="flix-btn flix-btn-default" @click.prevent="setPage(pages)">{{ pages }}</a></li>
    </ul>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    pages: Number,
    page: Number,
    breakpoint: Number,
    callback: Function
  },
  data () {
    return {
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setPage (id) {
      this.callback(id)
    }
  }
}
</script>
<style lang="sass" scoped>
  ul
    list-style-type: none
    padding: 0
    display: flex
    flex-direction: row
    list-style: none
    gap: 10px
    margin: 0
    align-items: center
    justify-content: flex-start
    margin: 10px 0
    a, b

      text-decoration: none
      display: flex
      align-items: center
      text-align: center
      justify-content: center
</style>
